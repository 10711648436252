.custom-domain-setup-steps {
  &.ant-steps {
    max-width: 64rem;
    margin: 1rem;
  }

  .ant-steps-item {
    &-icon {
      width: 2rem;
      height: 2rem;
      background: white;
      border: 2px solid #e5e7eb;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;

      .ant-steps-icon {
        color: #6b7280;
        font-size: 14px;
      }
    }

    &-content {
      text-align: left;
      padding-right: 1rem;
    }

    &-title {
      font-size: 0.875rem;
      font-weight: 500;
      color: #111827;
      line-height: 1.25;
    }

    &-description {
      font-size: 0.875rem;
      color: #6b7280;
    }

    &-tail {
      &::after {
        // background-color: #e5e7eb;
        background-color: var(--theme-primary);
      }
    }

    // Active state
    &-active {
      .ant-steps-item-icon {
        background: var(--theme-primary);
        border-color: var(--theme-primary);

        .ant-steps-icon {
          color: white;
        }
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-title,
      .ant-steps-item-description {
        color: #6b7280 !important;
      }
    }

    // Completed state
    &-finish {
      .ant-steps-item-icon {
        border-color: var(--theme-primary);
        background: var(--theme-primary);

        .anticon {
          color: var(--theme-primary);
        }
      }

      .ant-steps-item-tail::after {
        background-color: var(--theme-primary);
      }
    }
  }
} 