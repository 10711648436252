// fonts
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,500,700&display=swap');
@import url('../node_modules/react-circular-progressbar/dist/styles.css');
@import url('../node_modules/react-intl-tel-input/dist/main.css');
@import './sass/typeahead';
@import './sass/variables';
@import './sass/generic';
@import './sass/rewards.scss';
@import './sass/jobs';
@import './sass/body';
@import './sass/menu';
@import './sass/challenges';
@import './sass/drawer-search.scss';
@import './sass/dashboard';
@import './sass/forum';
@import './sass/modal';
@import './sass/v2';
@import './sass/profile-details';
@import './sass/projects';
@import './sass/admin-cohort.scss';
@import './sass/public-page.scss';
@import './sass/user-menu.scss';
@import './sass/top-menu.scss';
@import './sass/template/header.scss';
@import './sass/billing.scss';
@import './sass/transactions.scss';
@import './sass/cohort.scss';
@import './sass/calender.scss';
@import './sass/apprentice-v2.scss';
@import './../modules/assets/sass/drawer.scss';
@import './sass/components/learn-qureos';
@import './sass/pipeline-drawer.scss';
@import './sass/custom-domain-setup.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;
// Colors

.code-options {
  padding-top: 20px;

  .ant-checkbox {
    top: 4px;
  }

  .ant-checkbox-checked {
    &:after {
      height: 16px;
    }
  }
}

.more-menu {
  @apply bg-white rounded-lg shadow-lg p-3 flex flex-col relative;
  top: 20px;
  width: 200px;
}

.ant-tooltip {
  @apply z-10;
}

.mini-modal {
  max-width: 800px;
}

.pdf-loading {
  @apply w-full flex justify-center p-4 text-center;
}

.no-wrap {
  white-space: nowrap;
}

.no-scroll {
  overflow: hidden !important;
}

.required {
  @apply text-red-500 text-sm;
}

.hidden {
  display: none;
}

.no-padding {
  padding: 0 !important;
}

.Challenges {
  &__action-bar {
    @apply shadow-lg overflow-hidden;
    top: 66px;
    z-index: 11;
  }

  &__review-action-bar {
    @apply shadow-lg;
  }
}

@media (max-width: $mobile) {
  .hide-xs {
    display: none;
  }
}

.input-feedback {
  @apply block w-full p-4 mt-2;
  bacground-color: '#F7F7F7';

  &::-webkit-input-placeholder {
    font-size: 0.875rem;
  }
}

.min-h-200 {
  min-height: 10.5rem;
}

.padding-0 {
  padding: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.arrow {
  border: solid #4a4d64;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 250ms;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.transform-180 {
  transform: rotate(180deg);
  transition: transform 150ms;
}

.transform-0 {
  transform: rotate(0deg);
  transition: transform 150ms;
}

.transform-90 {
  transform: rotate(90deg);
  transition: transform 150ms;
}

.-transform-90 {
  transform: rotate(-90deg);
  transition: transform 150ms;
}

.color-picker-popover {
  @apply mx-auto fixed p-2 bg-white rounded shadow-md h-auto;
  top: 35%;
  left: 45%;
}

.challenge-resources-popover {
  @apply fixed h-auto w-1/3 m-5 shadow-md rounded;
  top: 20%;
  left: 40%;
}

.challenge-mentor-popover {
  @apply fixed h-auto w-1/3 m-5 shadow-md rounded;
  top: 20%;
  left: 25%;
}

.cus-divider {
  position: absolute;
  display: table;
  text-align: center;
  @apply my-10;

  height: 70%; // CHANGE ME!
  width: 100%;

  .center-data {
    position: relative;
    display: table-cell;
    vertical-align: middle;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      left: 50%;
      border-left: 1px solid #a0aec0;
      // height: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      width: 1px;
      left: 50%;
      border-left: 1px solid #a0aec0;
      // height: 100%;
    }

    &:before {
      bottom: 50%;
      top: 0;
      margin-bottom: 20px;
    }

    &:after {
      top: 50%;
      bottom: 0;
      margin-top: 20px;
    }
  }
}

.jobs-lock {
  position: absolute;
  background-color: #020202;
  opacity: 0.5;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.tooltip-text {
  background-color: #ffffff;
  position: absolute;
  margin: 20px;
  margin-left: 0px;
  padding: 15px;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  display: block;
  width: 130%;

  // text-align: center;

  &:after,
  &:before {
    left: 40%;
    bottom: 95%;
    border: solid transparent;
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffffff;
    border-width: 30px;
    margin-top: -30px;
  }

  h4 {
    color: #3aa4f3;
  }

  button {
    @apply bg-qureosBlue rounded-lg;
    padding: 5px 10px;
    margin: 0px auto;
  }
}

.div-tooltip {
  @extend .tooltip-text;
  @apply border border-gray-400;
  top: 80%;

  &::after {
    //add classes here
    margin-top: -30px;
  }
}

// form

a,
button {
  @apply cursor-pointer;

  transition: 0.2s;
}

button,
input {
  &:focus {
    @apply outline-none;
  }
}

.btn-v2 {
  @apply w-full flex;

  .btn {
    @apply text-qureosBlue py-2 text-center rounded-full mr-3;
    min-width: 142px;
    width: auto;
    background-color: #d9edfd;

    &.active {
      @apply bg-qureosBlue;
      color: white;
    }
  }
}

input,
textarea {
  @apply p-2 border-0 rounded outline-none;
  background-color: #f7f7f7;

  transition: border-color 0.2s;

  &:disabled {
    @apply border-gray-300;
  }
}

label {
  @apply block my-8;

  span {
    @apply block mb-4 font-medium;
  }

  textarea {
    min-height: 10em;
  }
}

// textarea {
//   min-height: 8em;
// }

// heading

h1,
h2,
h3,
h4 {
  @apply font-semibold;
}

h1 {
  @apply text-5xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

// table

table {
  thead {
    tr {
      @apply border-b border-gray-300;
    }
  }

  tbody {
    tr {
      @apply border-t border-gray-200;
    }
  }

  td,
  th {
    @apply align-middle text-left p-4;
  }

  th {
    @apply font-semibold;
  }
}

// layout

#__next {
  @apply flex flex-col;

  min-height: 80vh;

  main {
    @apply flex flex-col flex-1;
  }
}

// responsive

@media (min-width: 640px) {
  input,
  textarea {
    @apply w-full;
  }
}

// custom override

.chatheads-container {
  @apply w-24 border-r border-gray-400 flex flex-col items-center overflow-auto;
  height: calc(100vh - 95px);
}

.deliverable-form {
  @apply w-1/2 bg-white shadow-md p-4 mx-auto mt-32 z-20 fixed rounded h-auto;
  top: 0;
  left: 25%;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.overlay-light {
  filter: brightness(50%);
}

.popover {
  @apply w-64 h-64 p-8 rounded shadow-lg absolute bg-white;
  top: -280px;
  left: 10px;
}

.project-summary-details {
  @apply overflow-auto border-l border-gray-300 w-1/3;
  height: calc(100vh - 95px);
}

.react-datepicker-wrapper {
  width: 100%;
}

.send-button {
  @apply bg-blue-500 w-8 h-8 absolute text-center rounded-full;
  top: 27px;
  right: 1rem;

  img {
    transition: all 0.3s ease;
  }

  &:hover {
    img {
      transform: rotate(-360deg);
    }
  }
}

.unread-badge {
  @apply bg-red-500 text-white text-xs rounded-full py-1 px-2 absolute;
  top: 0;
  right: 0;
}

.verify-email-popover {
  @apply mx-auto fixed p-2 bg-white rounded shadow-md h-auto;
  top: 15%;
  left: 35%;
  width: 500px;
  z-index: 100;
}

.profile-popover {
  @apply mx-auto fixed p-2 bg-white rounded shadow-md h-auto;
  left: -25%;
  padding: 29px;
  top: 50%;
  width: 80%;
  transform: translateY(-50%);
}

.achievement-title {
  background-color: #2dce98;
}

.steps {
  @apply relative w-full flex justify-between;

  &:before {
    content: ' ';
    @apply border-b-4 border-gray-500 absolute;
    top: 42.5px;
    left: 20px;
    width: calc(100% - 60px);
  }

  .step {
    .step-label {
      @apply text-gray-500 text-center;
    }

    .step-page {
      @apply w-6 h-6 relative border-2 border-gray-500 rounded-full m-2 bg-white mx-auto;

      &:before {
        content: ' ';
        @apply rounded-full w-3 h-3 bg-gray-500 absolute;
        top: 4px;
        left: 4px;
      }
    }

    &.active {
      .step-label {
        @apply text-blue-500;
      }

      .step-page {
        @apply border-blue-500;

        &:before {
          @apply bg-blue-500;
        }
      }
    }
  }
}

ul.custom-ul {
  text-align: center;
  font-size: 12px;
  color: #2dce98;
}

ul.custom-ul li {
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

ul.custom-ul li::before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #8898aa;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 7px;
}

select.sort-by {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select.sort-by::after {
  content: 'V';
  display: block;
}

.transparent-select {
  background-color: #f7f7f7;

  .css-yk16xz-control {
    background: transparent;
    border: none;
    height: 33px;
    padding: 0;
    min-height: initial;
  }

  .css-26l3qy-menu {
    background: white !important;
    z-index: 2;

    @media (max-width: $mobile) {
      position: relative;
    }
  }

  .css-1pahdxg-control {
    box-shadow: none;
    border-color: transparent;
    background: transparent;
    height: 33px;
    padding: 0;
    min-height: initial;

    &:hover {
      box-shadow: none;
      border-color: transparent;
      background: transparent;
    }
  }

  &.as-textbox {
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  &.hide-value {
    .css-1rhbuit-multiValue,
    .css-1uccc91-singleValue {
      display: none;
    }
  }

  .css-1uccc91-singleValue {
    @apply text-gray-500;
  }
}

.school-item {
  @apply relative mb-4;

  &:before {
    content: ' ';
    @apply absolute w-6 h-6 rounded-full border-2 border-gray-400 bg-white;
    left: -40px;
    top: 50px;
  }

  &.active {
    &:before {
      @apply border-blue-500;
    }

    &:after {
      @apply bg-blue-500;
    }
  }

  &:after {
    content: ' ';
    @apply absolute w-3 h-3 rounded-full bg-gray-400;
    left: -34px;
    top: 56px;
  }
}

.schools {
  &.line {
    &:before {
      @apply h-auto absolute bg-gray-500;
      content: ' ';
      height: calc(100% - 145px);
      width: 2px;
      left: -28px;
      top: 60px;
    }
  }
}

// misc
.label {
  @apply m-0 mb-1 text-sm text-gray-600 uppercase;
}

.css-1rhbuit-multiValue {
  background: #39a0ed !important;
  color: white;

  .css-12jo7m5 {
    color: white;
  }
}

.nav-parent {
  @apply relative;

  &:before {
    @apply absolute bg-gray-500;
    content: '';
    width: 44%;
    height: 2px;
    bottom: 20%;
    left: 25%;
  }
}

.scoring-wrapper {
  @apply relative;

  .option-parent {
    @apply relative w-full;
  }

  .option {
    width: 20%;

    &__text {
      font-size: 0.7rem;
      line-height: 12px;
    }

    &__circle {
      height: 37;
      width: 70%;
      max-height: 42px;
      max-width: 36px;
    }
  }
}

body {
  background: #edf2f7;
}

.header-tab-underline {
  @apply border-4 border-qureosBlue absolute border-b w-full;
  bottom: -17px;
}

.header-tab-underline-job-description {
  @apply w-full border-4 border-blue-400 absolute border-b;
  margin-top: 10px;
}

.profile-label {
  @apply text-gray-600 uppercase pb-2 text-sm;
}

.edit-profile-icon {
  @apply relative;
  width: 20px;
  top: -20px;
  right: -15px;
}

.slick-slider {
  .slick-dots {
    @apply justify-center;
    display: flex !important;
    padding-top: 20px;

    li {
      @apply rounded-full;
      width: 19px;
      height: 19px;
      padding: 0;
      border-radius: 100%;
      margin: 0 5px;
      background: white;
      border: 3px solid #8898aa;

      &.slick-active {
        background: #8898aa;
      }

      button {
        font-size: 0;
        background: transparent;
        float: left;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
  }

  .slick-slide {
    float: left;

    div {
      outline: none;
    }
  }

  .slick-list,
  .slick-dots {
    width: 100%;
  }
}

.side-bar-thin-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    // height: 100%;
    border-radius: 100px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d0d3de;
    border-left: none;
    border-right: none;
    border-radius: 100px;
  }
}

.thin-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    height: 4px;
    border-radius: 100px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d0d3de;
    border-left: none;
    border-right: none;
    border-radius: 100px;
  }
}

.clearfix {
  clear: both;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.box-scrollbar {
  ::-webkit-scrollbar {
    width: 6px;
    @apply rounded-lg;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    margin-top: 0 !important;
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d0d3de;
    border-left: none;
    border-right: none;
    @apply rounded-lg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply rounded-lg bg-gray-500;
  }
}

.header-shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.pilltabs {
  .item {
    @apply bg-gray-200 text-blue-900 text-center flex-1 flex justify-center items-center px-4 text-xs;
    border-left: 1px solid #dee3e8;
    border-right: 1px solid #dee3e8;

    &:hover,
    &.active {
      @apply bg-gray-400 cursor-pointer;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
      @apply border-0;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
      @apply border-0;
    }
  }
}

.modal-content {
  @apply bg-white shadow rounded-lg;
  height: fit-content;

  &.v2 {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 200px;
  }

  &.transparent {
    background: transparent;
  }

  @media (max-width: $mobile) {
    left: 2.5%;
    width: 95%;
    top: 12%;
  }
}

.pagination {
  @apply flex;

  li {
    list-style: none;

    a {
      @apply w-10 h-10 flex items-center justify-center border border-gray-500 rounded-full mx-1 text-sm;
      transition: all 0.3s ease;

      &:hover,
      &.active {
        border: $qrs-blue solid 8px;
        color: #fff;
      }
    }
  }

  &.bg {
    li {
      a {
        @apply bg-white;
      }
    }
  }
}

.tab-links {
  @apply w-full flex bg-white rounded-tl-lg rounded-tr-lg px-12 items-center shadow-lg relative;
  height: 70px;
  box-shadow: -1px 1px 13px 0px rgba(0, 0, 0, 0.09);
  z-index: 1;
  min-width: 120px;

  @media (max-width: $mobile) {
    padding: 5px 10px;
  }

  a {
    @apply w-auto h-full text-base mr-16 relative flex items-center justify-start;
    transition: all 0.3s ease;

    @media (max-width: $mobile) {
      margin-right: 20px;
    }

    &:after {
      @apply bg-white absolute w-full;
      content: ' ';
      height: 6px;
      left: 0;
      bottom: 0;
      transition: all 0.3s ease;
    }

    &.active {
      @apply text-qureosBlue;

      &:after {
        @apply bg-qureosBlue;
      }
    }
  }
}

.tab-content {
  @apply w-full flex flex-col px-12 pb-12 pt-8 bg-white rounded-bl-lg rounded-br-lg text-sm;
  color: #576271;

  ol,
  ul {
    padding-inline-start: 40px;
    list-style: initial;
  }
}

.company-details {
  @apply flex;

  & > div:first-child {
    @apply w-2/3 border-r border-qureosGray mr-2;
    padding-right: 25px;
  }

  .company-industry {
    @apply flex justify-start w-full flex-wrap;

    & > div {
      @apply rounded-full py-2 px-4 flex items-center justify-center mr-2 mb-2;
      background-color: #eaedf0;
    }
  }
}

.notifications {
  @apply absolute bg-white rounded-lg w-full;
  max-width: 460px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
  top: 67px;
  right: 35px;

  @media (max-width: $mobile) {
    right: 0px;

    &:before {
      right: 85px !important;
    }
  }

  &:before {
    content: ' ';
    width: 0;
    height: 0;
    display: inline-block;
    border: 10px solid transparent;
    border-bottom-color: white;
    position: absolute;
    right: 36px;
    top: -20px;
  }

  & > .title {
    @apply w-full p-4 text-base text-qureosDarkGray;
  }

  .content {
    @apply w-full;
    max-height: 550px;
    overflow: auto;

    .notification {
      @apply w-full flex justify-start px-4 items-center relative;
      height: 60px;
      cursor: pointer;

      .icon {
        width: 50px;
        flex: none;
      }

      &:hover {
        background-color: #edf8ff;
      }

      &:before {
        content: ' ';
        width: 90%;
        position: absolute;
        height: 1px;
        background: #f1f1f3;
        top: 0;
        left: 22px;
      }

      &:nth-child(even) {
        background-color: #f7fafc;

        &:hover {
          background-color: #edf8ff;
        }
      }

      .icon {
        img {
          @apply w-8 mr-8;
        }
      }

      .title {
        @apply text-mutedBlack text-sm;
      }

      .message {
        @apply text-sm;
      }

      .age {
        position: absolute;
        right: 10px;
        bottom: 5px;
        font-size: 11px;
        color: #8898aa;
      }
    }
  }
}

.sessions {
  @apply w-full overflow-auto px-8;
  display: -webkit-box;

  .session-card {
    @apply px-2 pb-4 cursor-pointer;
    width: 286px;

    & > div {
      @apply relative overflow-hidden;

      &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 12px;
        @apply bg-qureosBrightGreen;
        left: 0;
        top: 0;
      }

      &.to-mentor {
        &:before {
          @apply bg-qureosPurple;
        }
      }

      .session-info {
        @apply flex w-full flex-col items-center py-4 pt-8 relative;
        box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.08);

        .level {
          @apply text-qureosDarkGray text-sm;
        }

        .separator {
          @apply my-2;
          width: 91px;
          height: 1px;
          border-top: 1px solid #d3dfea;
        }

        .skill {
          @apply font-semibold;
        }
      }

      .created-at {
        @apply text-qureosDarkGray absolute text-xs;
        top: 14px;
        left: 5px;
      }

      .session-details {
        @apply flex justify-center items-center h-32 px-3;
        word-wrap: break-word;
        background-color: #fbfbfb;
      }

      .session-footer {
        @apply w-full flex justify-between items-center px-3;
        height: 60px;
        box-shadow: -1px 3px 15px 7px rgba(0, 0, 0, 0.10980392156862745);
      }
    }
  }
}

.session-modal-info {
  @apply w-full border border-qureosDarkGray rounded-lg items-center py-4 flex mt-4;

  .section {
    @apply flex ml-2;

    .title {
      @apply text-qureosDarkGray text-sm;
    }

    .text {
      @apply text-qureosBlue text-sm;
    }

    &:first-child {
      @apply pl-4;
      width: 70px;

      &:after {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:not(:first-child) {
      @apply flex-1;
    }

    &:after {
      @apply bg-qureosDarkGray mx-6;
      content: ' ';
      width: 1px;
    }
  }
}

.Card {
  @apply relative;
}

.challenge-info {
  @apply absolute rounded-full w-8 h-8 flex items-center justify-center p-2;
  background-color: rgb(59, 208, 152);
  top: -12px;
  right: -12px;

  img {
    @apply w-6 h-6;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .progress-bar-content {
    @apply relative;

    span.percentage {
      @apply text-2xl;
    }

    span.info {
      @apply text-xs absolute;
      bottom: -21px;
      left: -19px;
    }
  }
}

/*** Custom Design Override **/
/*******************************/

body {
  overflow-x: hidden;
}

.qrs-sidebar {
  &.close {
    .profile-badge-box {
      .profile-strength {
        width: 64px;
        height: 64px;
        position: absolute;
      }

      .profile-dp {
        width: 64px;
        height: 64px;
      }
    }

    .qrs-generic-badge {
      flex: none;
      width: fit-content;
      margin: 5px auto;
    }

    & > .h-4 {
      display: none;
    }

    .qrs-logo {
      display: none;
    }
  }

  .profile-badge-box {
    position: relative;

    .profile-strength {
      width: 70px;
      height: 70px;
      position: absolute;

      .progress-bar-content {
        display: none;
      }
    }

    .profile-dp {
      width: 70px;
      height: 70px;
      padding: 5px;
    }
  }

  .verified-badge {
    position: absolute;
    left: -1px;
    width: 20px;
  }
}

.qrs-color-blue {
  color: $qrs-blue;
}

.qrs-m-auto {
  margin: 0 auto;
}

.qrs-mobile-placholder {
  display: none;

  @media (max-width: $mobile) {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 999999999;
    text-align: center;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 80%;

      .qmp-logo {
        margin: 0 auto;
      }

      .text {
        margin-top: 10px;
        color: $qrs-blue;
        font-size: 100%;
      }
    }
  }
}

$delay: 0.3s;

.qrs-transition {
  -webkit-transition: all $delay ease-out;
  transition: all $delay ease-out;
}

.qrs-quick-info-box {
  width: auto;
  margin: 0px;

  img {
    display: inline-block;
    vertical-align: sub;
  }

  .ib-item {
    width: 100%;
    // font-weight: bold;
  }
}

.header-infobox {
  width: 100%;
  clear: both;
  display: none;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
    display: flex !important;
  }

  .user-points {
    display: flex;

    .qrs-quick-info-box {
      .qrs-generic-badge {
        .icon {
          background: white;
          border-radius: 24px;
        }

        &.xp {
          border: solid 1px #fdeecb;
          background: #fdf9f0;
          margin-right: 10px;
        }

        &.session {
          border: solid 1px #cce9ff;
          background: #f2f9ff;
        }
      }

      .ib-item {
        font-size: 25px;

        img {
          width: 30px;
        }
      }
    }
  }
}

.qrs-main-content {
  @apply w-full pb-8 pr-6 relative;

  @media (max-width: $mobile) {
    padding: 10px;
    padding-top: 80px;
    padding-left: 0 !important;
    padding-right: 0;
  }

  padding-left: 100px;
  padding-top: 100px;

  &.pushed {
    @media (max-width: $mobile) {
      padding-left: 10px;
    }

    padding-left: 120px !important;
  }
}

.qrs-login-box {
  @media (max-width: $mobile) {
    @apply px-4;
  }

  min-width: 300px;
  max-width: 450px;
  height: auto;
  margin: 0 auto;
  padding-top: 10px;

  &.wider {
    max-width: 600px;
  }

  .logo {
    margin: 0 auto;
    max-width: 120px;
  }

  .shadow {
    // box-shadow: #ccc 0px 0px 13px;
  }

  .error-msg {
    font-style: normal;
    text-align: left;
  }

  .button-container {
    @apply w-full;

    &.signup {
      @apply w-full flex justify-between items-center;

      a {
        @apply text-qureosBlue text-left;
      }
    }

    button {
      display: inline-block;
    }
  }

  .l-btn {
    font-weight: bold;
    font-size: 16px;
    float: right;
  }

  .referrer-box {
    .referrer-dp {
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      border: solid 2px $qrs-blue;
      margin-right: 5px;
      object-fit: cover;
    }

    .name {
      font-weight: bold;
    }
  }

  .footer-links {
    text-align: center;
    padding: 10px;
    padding-top: 20px;
    font-size: 13px;

    .link-item {
      margin-left: 5px;
      margin-right: 5px;
      font-weight: bold;
      color: $qrs-blue;
      cursor: pointer;
    }
  }
}

.auth-form-footer-btn {
  @apply flex justify-center items-center;
  background: white;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  max-width: 180px;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  height: 45px;

  img {
    width: 75px;
    margin-right: 10px;
    position: relative;
    top: -3px;
    cursor: pointer;
  }
}

.qrs-full-container {
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9;
}

.qrs-form-tabs {
  ul.header {
    list-style: none;

    li {
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
      padding: 20px;
      text-align: center;
      background: #e6efef;
      color: #738f93;
      cursor: pointer;

      &:hover {
        background: #eee;
      }

      &.active {
        background: #fff;
        color: #555;
        box-shadow: #eee 6px -1px 18px -15px;
        position: relative;
        cursor: default;

        &:hover {
          background: #fff;
        }
      }
    }
  }

  .tab-contents {
    padding: 20px;

    input {
      height: 50px;
      border-radius: 0px;
      outline: none;
      box-shadow: #eee 0px 0px 8px -1px;
      background: #f3f7f7;
      color: #738f93;

      &:focus {
        border: solid 1px #3fa3f3;
      }
    }
  }
}

.social-button {
  margin: 0 auto;
  background: #eee;
  padding: 10px;
  display: inline-block;
  width: auto;
  // min-width: 200px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;

  img {
    display: inline-block;
    height: 30px;
    width: auto;
    margin: 0px;
  }

  text {
    display: inline-block;
    color: white;
  }

  &.fb {
    background: #1877f2;
  }

  &.google {
    background: #000;
  }
}

.textline {
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0px;
    background: #ccc;
    height: 1px;
    width: 100%;
  }

  .text {
    position: relative;
    z-index: 2;
    padding: 10px;
    display: inline-block;
    margin: 0 auto;
    width: auto;
    color: #666;
    background: #fff;
  }
}

.qrs-gradient {
  &.gradient-plain {
    $g1: #fff;
    $g2: #eee;
    background: $g1;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from($g1),
      to($g2)
    );
    background: linear-gradient(to right, $g1, $g2);
  }

  &.gradient-default {
    $g1: #698cd8;
    $g2: #3fa3f3;
    background: $g1;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from($g1),
      to($g2)
    );
    background: linear-gradient(to right, $g1, $g2);
  }

  &.gradient-plainz {
    background: #606c88;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3f4c6b, #606c88);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.qrs-btn {
  background: $qrs-blue;
  min-width: 80px;
  text-align: center;
  display: block;
}

.qrs-btn-plain {
  background: $qrs-blue;

  &.px-20 {
    @media (max-width: $mobile) {
      @apply px-0 w-full;
    }
  }
}

/* Profile */

.qrs-profile-details {
  width: 1024px;
  height: calc(100vh - 20vh);

  @media (min-height: $long-screen) {
    height: calc(100vh - 30vh);
  }

  .dp-side {
    width: 12%;
    padding-right: 10px;
  }

  .dob-field {
    width: 24.5%;
  }

  .dob-field-spacer {
    width: 23.8%;
  }

  .phone-field {
    width: 37%;
    flex: none;
  }

  input,
  textarea {
    background: $qrs-dirty-white;
    border-radius: 0px;
    resize: none;
  }

  .qrs-select {
    position: relative;
    z-index: 99;

    background-color: $qrs-dirty-white;
    border-radius: 0px;

    div {
      text-transform: capitalize;
    }
  }

  .upload-label {
    font-size: 11px;
    text-align: center;
    margin-top: 6px;
  }

  // .phone-verification-box {
  //   position: relative;
  //   left: 30%;
  //   top: -39px;
  // }
}

.qrs-profile-details-card-box {
  height: auto;
  min-height: 200px;

  .card-item {
    background: white;
    border-radius: 5px;
  }
}

.qrs-profile-education {
  width: 1024px;
  height: calc(100vh - 30vh);

  .school-item {
    position: relative;
    background: #fefefe;
    padding: 10px;

    .school-name-box {
      input {
        width: 250px;
      }
    }

    .remove-school-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
      background: gray;
      cursor: pointer;
      padding: 6px;
      border-radius: 5px;
      line-height: 2px;
    }

    .verify-btn {
      position: relative;
      left: -5px;
      top: 5px;
      height: 30px;
      line-height: 15px;

      @media (max-width: $mobile) {
        flex: none;
      }
    }

    .send-code-btn {
      height: 30px;
      margin-top: 5px;
      line-height: 14px;
    }

    .email-verification-info {
      @media (max-width: $mobile) {
        flex-wrap: wrap;
        width: 100%;
      }

      .send-code-btn {
        height: 30px;
        line-height: 15px;
        width: 82px;
        margin-top: 4px;

        @media (max-width: $mobile) {
          position: static;
          flex: none;
          display: block;
          margin-top: 10px;
        }
      }
    }

    .verification-box {
      @media (max-width: $mobile) {
        width: 80%;

        a {
          padding-top: 10px;
        }
      }
    }
  }
}

.qrs-profile-dp {
  overflow: hidden;
  height: 200px;
  width: 200px;
  object-fit: cover;
  background: #eee;
  margin: 0 auto;
  margin-bottom: 20px;

  &.dp-small {
    width: 100px;
    height: 100px;
  }

  &.placeholder {
    padding: 5px;
    border-radius: 0px;
    background: transparent;
  }
}

.qrs-profile-summary {
  background: white;

  .left-navig {
    width: 25%;
    min-height: 300px;

    .traits-tag {
      padding: 15px;
      font-size: 14px;

      div {
        width: auto;
        display: inline-block;
      }
    }
  }

  .right-navig {
    width: 75%;
    min-height: 300px;
    // background: #ddd;

    .edit-profile-icon {
      right: -2px;
      top: -4px;
    }

    .upper-deck {
      border-bottom: solid 1px #eee;
      padding-bottom: 10px;
    }

    .field-ctrl {
      display: inline-block;
      vertical-align: top;

      &:nth-child(1),
      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 30%;
      }

      .field-item {
        margin-bottom: 15px;
        min-height: 60px;

        &:nth-child(2) {
          margin-bottom: 0px;
          min-height: 40px;
        }

        .profile-label {
          display: block;
        }

        .profile-value {
          font-weight: 600;
          font-size: 15px;
        }
      }

      .profile-strength {
        margin-bottom: -18px;
      }
    }
  }

  .left-navig,
  .right-navig {
    display: inline-block;
    vertical-align: top;
    padding: 15px;
  }

  @media (max-width: $tablet) {
    .traits-tag {
      margin: 0 auto;
    }

    .left-navig,
    .right-navig {
      width: 100%;
    }

    .right-navig {
      .edit-profile-icon {
        right: 10px;
        top: 10px;
      }

      .field-ctrl {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 50%;
        }

        width: 50%;

        .field-item {
          padding-bottom: 25px;
        }
      }

      .profile-strength {
        float: left;
      }
    }
  }
}

.skill-item {
  .remove-skill-btn {
    position: relative;
    color: red;
    cursor: pointer;
    left: 4px;
    top: 4px;
  }
}

.skill-autocomplete,
.autocomplete-list {
  text-align: left;
  padding: 0px;
  overflow: hidden;

  ul {
    padding: 0px;
    width: 100%;

    li {
      border: none;
      padding: 10px;
      margin: 0px;
      font-weight: bold;
      font-size: 13px;

      &:hover {
        color: white;
        background: $qrs-blue;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

.qrs-modal {
  position: fixed;
  top: 55%; // navigation header adjustment
  left: 55%; // sidebar adjustment
  transform: translate(-50%, -50%);

  &.default {
    $pixelValue: 50%;
    top: $pixelValue;
    left: $pixelValue;
    transform: translate(-$pixelValue, -$pixelValue);
  }

  .mobile-popup-close-btn {
    display: none;
  }

  &.custom-top {
    top: 25%;
  }
}

.modal-content {
  position: fixed;

  .scroll-content {
    overflow: auto;
    overflow-x: hidden;

    &.default-height {
      height: fit-content; //calc(100vh - 200px);
      min-height: 300px;
      max-height: calc(100vh - 200px);
    }
  }

  .close-btn {
    cursor: pointer;
    color: #000;
    font-weight: 300;
    font-size: 20px;
    font-family: Arial, sans-serif;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
  }
}

.qrs-referral-box {
  width: 1000px;
  max-width: 100%;

  @media (max-width: $mobile) {
    overflow: auto;
    height: calc(100vh - 80px);
  }

  input,
  textarea {
    background: $qrs-dirty-white;
    resize: none;
  }

  .more-infobox {
    padding: 10px;
    width: 100%;
    border: solid 1px $qrs-blue;
    border-radius: 5px;
    font-size: 14px;
    color: #555;
  }
}

.qrs-faq-container {
  margin: 0 auto;
  text-align: center;

  .header {
    margin-bottom: 20px;
  }

  .acc-box {
    margin-top: 20px;

    .acc-header {
      @apply text-qureosSecondary;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    [data-accordion-component='AccordionItemButton'] {
      @apply bg-qureosSecondary;
      color: white;
    }

    .content {
      text-align: left;
    }
  }
}

.resources-report-container {
  @apply flex flex-col;
  min-height: 30px;
  margin-top: -10px;
  height: 450px;

  .report-btn {
    float: right;
    cursor: pointer;
    height: 20px;
  }

  .rsrcs-box {
    .item {
      display: inline-block;
      width: 100px;
      padding: 10px;
      cursor: pointer;

      &.active {
        border: solid 5px $qrs-blue;
      }

      img {
        margin: 0 auto;
      }
    }
  }

  .action-box {
    text-align: center;

    label {
      font-size: 15px;
      margin: 0px;
      padding: 10px;
    }

    .action-item {
      display: inline-block;
      margin: 0 auto;
      margin-right: 5px;
      width: 80px;
      height: 60px;
      padding: 10px;
      border-radius: 10px;
      font-size: 12px;
      cursor: pointer;
      border: solid 1px #ccc;
      vertical-align: top;

      &.active,
      &:hover {
        background-color: $qrs-blue;
        color: white;
      }
    }
  }
}

.reactour__helper {
  max-width: 1000px !important;

  .eTpeTG {
    height: 15px;
    width: 15px;
    cursor: pointer;

    svg {
      height: 18px;
    }

    &:hover {
      background: transparent;
      color: $qrs-blue;
    }
  }

  [data-tour-elem='left-arrow'],
  [data-tour-elem='right-arrow'] {
    height: 25px;
    width: 25px;

    svg {
      height: 30px;
    }
  }

  [data-tour-elem='left-arrow'] {
    margin-right: 35px;
  }
}

.qrs-tour-navigator {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateY(-50%);
  height: 0px;
  width: 0px;
  // background: red;
  z-index: -1;
  // visibility: hidden;
}

.qrs-tour-container {
  padding: 10px;
  margin: 10px;
  width: 300px;

  &.large {
    width: 800px;
    min-height: 350px;
  }

  .banner-image {
    margin: 0 auto;
    padding-top: 10px;
    min-height: calc(100vh - 40vh);
    max-height: 500px;
    object-fit: scale-down;
    object-position: top;
  }
}

.qrs-tour-prompt {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999999;
  background: #0000009c;

  .content {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    max-height: 200px;
    height: 100%;
    padding: 20px;
    box-shadow: #aaa 0px 0px 13px;
    text-align: center;

    .prompt-title {
      color: $qrs-blue;
      font-size: 25px;
      margin-bottom: 20px;
    }

    .prompt-actions {
      width: 100%;

      button {
        display: inline-block;
        padding: 10px;
        font-weight: bold;
        margin: 5px;
        border-radius: 0px;
        min-width: 100px;
      }
    }
  }
}

.blurred-content {
  display: inline;
  margin-left: -5px;
  filter: blur(4px);
  background: white;
  margin-left: -35px;
}

.qrs-datepicker {
  max-height: 0px;
  overflow: hidden;
  height: auto;
  transition: all 0.3s ease-in;
  width: 300px;
  position: absolute;
  z-index: 100;

  &.top-position {
    margin-top: -350px;
  }

  &.bottom-position {
    margin-top: 0px;
  }

  &.active {
    max-height: 1000px;
  }

  .DayPicker {
    background: #efefef;
    color: $qrs-blue;
    border-top: solid 5px $qrs-blue;

    .DayPicker-Day {
      border-radius: 0px !important;

      &:hover {
        background: white;
        cursor: pointer;
      }
    }

    .DayPicker-Caption {
      select {
        margin-right: 10px;
      }
    }
  }
}

/* Plugin Override */

/* over write css in react-select module */
.css-26l3qy-menu {
  & > div {
    max-height: 120px !important;
    overflow: auto;
  }
}

.swal2-container {
  .swal2-actions {
    .swal2-confirm {
      background-color: $qrs-blue !important;
      font-weight: bold;
    }

    .swal2-cancel {
      background-color: #cc0000 !important;
      font-weight: bold;
    }
  }
}

.transparent-select {
  .css-1uccc91-singleValue {
    color: #000;
  }
}

.ck-content {
  ul,
  ol {
    list-style: initial;
  }

  ol {
    list-style-type: decimal;
  }
}

.qrs-debug {
  width: auto;
  word-break: break-word;
  white-space: normal;
  padding: 10px;
  background: #eee;
}

[data-tour-elem='left-arrow'],
[data-tour-elem='right-arrow'] {
  &:hover {
    cursor: pointer;
    color: $qrs-blue !important;
    background: transparent;
  }
}

.challenge-submit-textarea {
  .text-editor {
    height: calc(100% - 90px);
  }

  div[data-contents='true'] {
    max-height: 410px;
  }
}

.challenge-submission-editor {
  width: calc(100% + 24px);
  position: absolute;
  left: -12px;

  .ck-editor {
    width: 100% !important;
    border: none !important;
    height: calc(100% - 120px) !important;

    .ck-toolbar {
      border: solid 1px #eee !important;
    }

    .ck-editor__editable {
      &.ck-focused {
        box-shadow: none;
      }

      border: none !important;
      border-bottom: solid 1px #eee !important;
      height: calc(42rem - 9rem);
    }
  }
}

.qrs-tour-menu {
  color: $qrs-blue;
  font-size: 22px;
}

.challenge-wrapper {
  @apply mx-auto pt-8;
  width: 100%;
  max-width: 1260px;
}

// CKEDITOR
.ck-content {
  ol,
  ul {
    padding-inline-start: 40px;
  }
}

// END OF CKEDITOR

.query {
  .css-2b097c-container {
    @apply mx-2;

    .css-yk16xz-control {
      background: transparent;
      border: none;
      color: black;
      font-size: 15px;
    }

    .css-26l3qy-menu {
      font-size: 15px;
    }
  }

  .typeahead {
    @apply px-2;
  }

  p {
    @apply px-2;
  }

  input {
    background: transparent;
    border-bottom: 1px solid #8b8f92;
    border-radius: 0;
  }
}

.users {
  @apply flex flex-col;

  .item {
    @apply border-b px-6 flex py-6 items-center;

    .info {
      @apply px-6;
    }
  }
}

.parsed-content {
  @apply whitespace-pre-wrap;

  ol,
  ul {
    padding-left: 40px;
    list-style: initial;
  }

  ol {
    list-style-type: decimal;
  }

  .table {
    box-shadow: none;

    tbody {
      td {
        box-shadow: none;
        background-color: transparent;
        color: initial;
      }
    }
  }

  &.text-xs {
    font-size: 13px;
  }

  a {
    @apply text-qureosPrimary block;
    display: inline;
    padding-right: 4px;
  }
}

.transparent-header .ant-table-thead > tr > th {
  background-color: transparent;
  color: #6B7280;
}

.delete-indicator {
  @apply absolute rounded-full text-center text-white;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.85);
  top: -6px;
  left: 0;
}

.credit-card {
  .card {
    width: 300px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;
  }

  .card__part {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 300px;
    height: 190px;
    background-image: url('https://image.ibb.co/bVnMrc/g3095.png'),
      linear-gradient(
        to right bottom,
        #3aa4f3,
        #184263,
        #184263,
        #092337,
        #3aa4f3
      );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  .card__front {
    padding: 18px;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
  }

  .card__back {
    padding: 18px 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
  }

  .card__black-line {
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
  }

  .card__logo {
    height: 16px;
  }

  .card__front-logo {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .card__square {
    border-radius: 5px;
    height: 30px;
  }

  .card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .card__space-75 {
    width: 75%;
    float: left;
  }

  .card__space-25 {
    width: 25%;
    float: left;
  }

  .card__label {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
  }

  .card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .card__back-content {
    padding: 15px 15px 0;
  }

  .card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
  }

  .card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position: relative;
  }

  .card__secret:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(
      45deg,
      #ededed,
      #ededed 5px,
      #f9f9f9 5px,
      #f9f9f9 10px
    );
  }

  .card__back-logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .card__back-square {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
}

// .card:hover .card__front {
//   -webkit-transform: rotateY(180deg);
//   -moz-transform: rotateY(180deg);
// }

// .card:hover .card__back {
//   -webkit-transform: rotateY(0deg);
//   -moz-transform: rotateY(0deg);
// }
.skill-card {
  box-shadow: 0px 0px 30px #00000026;
}

$info-box-shadow: 0px 3px 6px #3e44571a;

.skill-info-box {
  box-shadow: $info-box-shadow;

  img {
    width: 100px;
    object-fit: cover;
  }
}

.evaluation-info-box {
  box-shadow: $info-box-shadow;
}

.dashboard-skill-slider {
  position: relative;
  // overflow: hidden;
  height: auto;
  width: 100%;
  text-align: center;

  .pagination {
    position: absolute;
    right: 0px;
    top: -60px;
  }

  .card-box-container {
    width: 256px;
    margin-left: 10px;

    @media (max-width: $desktop-4) {
      padding-left: 15px;
    }

    &.active-challenge {
      width: 256px;

      @media (max-width: $desktop-3), (max-width: $desktop-4) {
        position: relative;
      }

      @media (min-width: $desktop-5) {
        position: relative;
      }

      @media (min-width: $desktop-6), (min-width: $desktop-7) {
        position: relative;
      }

      @media (min-width: $desktop-8) {
        position: relative;

        .skill-card {
          height: 340px;
        }
      }
    }

    .skill-card {
      height: 275px;
      flex: none;
      box-shadow: 0px 0px 20px #00000026;

      @media (max-width: $desktop-4) {
        height: 270px;

        &.single-skill {
          margin-left: 15px;
        }
      }

      @media (min-width: $desktop-8) {
        height: 343px;

        &.single-skill {
          height: 330px;
        }
      }
    }
  }

  .slick-slider {
    height: 320px;

    @media (min-width: $desktop-8) {
      height: 365px;
    }

    .slick-list {
      overflow: hidden;
      // width: 20.2vw !important;
      min-width: 280px;
      padding-top: 25px;
      position: relative;

      @media (max-width: $desktop-4) {
        top: -20px;
      }

      @media (max-width: $desktop-5) {
        .card-box-container {
          margin-left: 0px;
        }
      }

      @media (max-width: $desktop-7) {
        top: -18px;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: -20px;
      text-align: center;

      @media (max-width: $desktop-7) {
        bottom: -40px;
      }

      li {
        border-width: 1px;
        height: 10px;
        width: 10px;
      }
    }
  }
}

.CircularProgressbar {
  .CircularProgressbar-text {
    font-size: 28px;
  }
}

.job-card-shadow {
  box-shadow: 0px 0px 30px #3e445726;
}

/***** NEW UI *******/

$text-head: 18px;
$text-subhead: 16px;
$text-content: 15px;
$text-small: 13px;
$text-mini: 12px;

.qrs-dashboard-cards {
  width: 100%;
  max-width: 336px;
  height: 425px;
  box-shadow: 0px 0px 30px #6a708026;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 50px;
  text-align: left;

  .dc-header {
    background: #3aa4f3;
    height: 45px;
    padding: 12px 0px 8px 20px;
    width: 100%;
    position: relative;

    label {
      text-transform: uppercase;
      color: #fff;
      margin: 0px;
      font-size: 18px;
    }

    &.lg {
      height: 95px;
    }

    .action-btn {
      position: absolute;
      top: 18px;
      right: 10px;

      img {
        cursor: pointer;
      }
    }

    .user-info-box {
      width: 100%;
      height: 70px;
      color: white;

      .user-dp {
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 70px;
        border-radius: 10px;
        padding: 2px;
        background: white;
        object-fit: cover;
      }

      .user-details {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
        height: 70px;

        li {
          margin-bottom: 3px;
          font-size: $text-mini;
          list-style: none;

          &.name {
            font-size: $text-head;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 170px;
            white-space: nowrap;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .g-header {
    font-size: 15px;
    font-weight: 600;
    @apply text-blackText;
    padding: 15px;

    .icon {
      margin: 3px 0px 0px 10px;
    }

    .sub-title {
      color: #576271;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .personality-box {
    padding: 0px 15px 15px 15px;

    .tag-item {
      background: #f2f9ff;
      border-radius: 20px;
      padding: 5px 20px;
      height: 30px;
      @apply text-qureosBlue;
      font-size: 15px;
      width: 130px;
      text-align: center;
      text-transform: uppercase;
    }

    .desc {
      margin-top: 15px;
      font-size: 14px;
      color: #576271;
      margin-bottom: 20px;
    }
  }

  .talent-phrase {
    &:first-child {
      margin-top: 16px;
    }

    padding: 0px 15px 15px 15px;
    margin-bottom: 20px;
    font-size: 14px;
    @apply flex flex-wrap;

    strong {
      font-weight: 600;
      @apply text-qureosBlue inline-block;
      display: flex;
      flex-wrap: wrap;
      margin: 0px 3px;

      p {
        margin-right: 5px;
      }
    }
  }

  .body-box {
    &.sm {
      height: 326px;
    }

    width: 100%;
    overflow: auto;
    height: 355px;

    @media (max-width: $mobile) {
      height: auto !important;
      overflow: visible;
    }

    .portfolio-card {
      margin-right: 0px;
    }

    .text-description {
      padding: 15px;
      padding-left: 20px;
      color: #576271;
      font-size: $text-content;
      word-break: break-word;
    }

    .plain-description {
      color: #576271;
      font-size: 14px;
      padding: 0px 10px;
    }

    .details-section {
      border-bottom: solid 1px #ebeef1;
      padding: 12px;
      min-height: 95px;

      .placeholder-box {
        text-align: center;
        cursor: pointer;

        &.full {
          margin-top: 55px;
        }

        .title {
          color: #8898aa;
          font-size: $text-content;
          font-weight: 600;
        }

        .subtitle {
          color: #6a7a8b;
          font-size: $text-mini;
          font-weight: normal;
        }

        .ph-btn {
          display: inline-block;
          font-size: $text-subhead;
          color: #fff;
          text-transform: uppercase;
          background: #3aa4f3;
          padding: 10px;
          border-radius: 20px;
          width: auto;
          min-width: 150px;
        }

        img {
          width: 43px;
          height: 43px;
          margin: 0 auto;
          margin-top: 10px;

          &.large {
            width: 102px;
            height: 102px;
            margin-top: 20px;
          }
        }
      }

      &:last-child:not(:first-child),
      &.no-line {
        border-bottom: none;
      }

      .header-title {
        font-size: $text-content;
        font-weight: 600;
        margin: 0px;
        color: #1a1e2c;
        margin-bottom: 10px;
        padding-left: 8px;
      }

      .header-subtitle {
        font-size: $text-mini;
        margin: 0px;
        color: #6a7a8b;
        margin-top: -10px;
        margin-bottom: 10px;
        padding-left: 8px;
      }

      .text-list {
        padding-left: 8px;
      }

      .traits-tag {
        div {
          background: #3aa4f3;
          color: white;
          border-radius: 20px;
          font-size: $text-subhead;
          padding: 5px;
        }
      }

      .item-tags {
        width: auto;
        min-width: 70px;
        text-align: center;
        border: 1px solid #3fa3f3;
        border-radius: 25px;
        font-size: $text-small;
        color: #3fa3f3;
        padding: 5px 8px;
        display: inline-block;
        text-transform: capitalize;

        &.secondary-tags {
          border: 1px solid #b443d0;
          color: #b443d0;
        }

        margin: 5px;

        &.counter {
          background: #ebeef1;
          color: #1a1e2c;
          font-size: $text-content;
          min-width: 50px;
          border: none;
          padding: 5px;
        }
      }

      .paragraph {
        .title {
          font-size: $text-content;
          font-weight: 600;
          color: #1a1e2c;
          margin-bottom: 10px;
        }

        .subtitle {
          font-size: $text-mini;
          color: #1a1e2c;
          margin-bottom: 10px;
        }

        .dates {
          font-size: $text-mini;
          color: #576271;
          margin-bottom: 10px;
          display: block;
        }

        .content {
          font-size: $text-small;
          color: #576271;
        }
      }

      .list-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-content: center;

        li {
          display: flex;
          flex: 1;
          align-items: center;

          .icon-item {
            // border-right: solid 1px #ccd7e3;
            display: inline-block;
            vertical-align: middle;
            // padding-right: 12px;
            min-width: 25px;

            img {
              height: 13px;
              min-width: 25px;
            }
          }

          .content {
            // margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
            color: #6a7a8b;
            font-size: $text-mini;
          }
        }
      }

      .text-list {
        .title {
          color: #6a7a8b;
          font-size: $text-mini;
          margin: 0px;
          margin-bottom: 3px;
          text-transform: uppercase;
        }

        .content {
          color: #1a1e2c;
          font-size: $text-content;
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
}

// Personality Traits Popup Styling
.ant-popover-inner {
  border-radius: 10px;
}

.trait-popup {
  @apply flex flex-col items-center;
  width: 300px;
  padding: 10px;
  text-align: center;

  .trait-name {
    @apply flex-1 px-2;
    background: #3aa4f3;
    color: white;
    border-radius: 20px;
    font-size: $text-subhead;
    padding: 5px;
    width: 50%;
  }

  .trait-description {
    margin-top: 20px;
  }
}

// Needs further checking before removal
.custom-checkbox {
  .item-check {
    border: 1px solid #ebeef1;
    padding: 10px 8px 8px 10px;
    min-width: 160px;
    display: inline-block;
    color: #576271;
    font-size: $text-content;
    border-radius: 10px;
    margin: 5px;
    margin-left: 0px;
    cursor: pointer;

    .label {
      text-transform: capitalize;
    }

    &.large {
      @apply w-full flex items-center;
      min-width: 319px;
      padding-top: 12px;
      padding-bottom: 9px;
      margin-bottom: 10px;

      @media (max-width: $mobile) {
        min-width: auto;
        max-width: 319px !important;
      }

      .content {
        width: 250px;
      }

      .content p::first-letter {
        text-transform: uppercase;
      }
    }

    .content {
      display: inline-block;
      vertical-align: middle;
    }

    .sub-content {
      color: #8898aa;
      font-size: $text-small;
    }

    &.active {
      .checker {
        border: 5px solid #2699fb;

        &::before {
          content: ' ';
          margin: 4px;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #2699fb;
          display: block;
        }
      }
    }

    .checker {
      border-radius: 100%;
      border: 5px solid #ebeef1;
      height: 30px;
      width: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.profile-edit-modal {
  display: block;
  padding: 20px;
  overflow: auto;
  max-height: 90vh;

  input,
  select {
    background: white !important;
    border: 1px solid #ebeef1;
    border-radius: 10px;
  }

  textarea {
    background: #fff !important;
  }

  hr {
    border: 2px solid #ebeef1;
    height: 0px;
    width: 100%;

    &.half {
      width: 50%;
    }
  }

  .modal-header {
    width: 100%;

    .title {
      color: #1a1e2c;
      font-size: $text-head;
      font-weight: 600;
    }

    .subtitle {
      color: #576271;
      font-size: $text-content;
    }
  }

  .section-box {
    width: 100%;
    display: block;
    margin-bottom: 15px;

    &.spaced {
      margin-bottom: 25px;
    }

    .header {
      color: #1a1e2c;
      font-size: $text-content;
      font-weight: 600;
      margin: 0px;
      margin-bottom: 15px;
    }

    .columns {
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }

    .form-input {
      display: inline-block;
      vertical-align: top;
      width: 230px;
      min-width: 200px;
      margin-right: 3px;

      &.lg {
        width: 400px;
      }

      .number-code {
        width: 100px;
        margin-right: 0px;
        position: relative;
        z-index: 1;
      }
    }

    .remove-item-btn {
      color: white;
      background: #ccc;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 5px;
      display: inline-block;
      text-align: center;
      vertical-align: bottom;
      margin-bottom: 3px;
    }
  }

  .section-title {
    color: #1a1e2c;
    font-size: $text-content;
    font-weight: 600;

    .sm-title {
      font-size: $text-mini;
      font-weight: normal;
    }
  }

  .action-item {
    font-size: 14px;
    color: #000;

    img {
      display: inline-block;
      cursor: pointer;
      margin-right: 5px;
    }
  }

  .custom-form {
    .form-section {
      margin-top: 15px;
      margin-right: 10px;

      &.datepick-box {
        position: relative;

        input {
          width: 190px;
          display: inline-block;
          vertical-align: top;
        }

        .picker-icon {
          display: inline-block;
          vertical-align: top;
          width: 43px;
          height: 43px;
          border-radius: 0px 10px 10px 0px;
          background: #ebeef1;
          margin-left: -4px;

          img {
            margin: 12px;
          }
        }
      }

      label {
        color: #6a7a8b;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0px;
      }

      input {
        border: 1px solid #ebeef1;
        border-radius: 10px;
        padding: 10px;
        width: 285px;
        color: #8898aa;

        font-size: 14px;
      }

      .textarea-counter {
        float: right;
        color: #6a7a8b;
        font-size: 15px;
        margin-right: 10px;
      }

      textarea {
        color: #576271;
        height: 85px;
        resize: none;
        font-size: 14px;
        border: 1px solid #ebeef1;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
      }
    }
  }

  .item-tags {
    border: 1px solid #576271;
    border-radius: 25px;
    display: inline-block;
    padding: 5px 10px;
    color: #576271;
    font-size: 15px;
    text-align: center;
    width: auto;
    min-width: 50px;
    margin: 5px;

    &.sm {
      padding: 5px 7px;

      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }

  .transparent-select {
    background: transparent;
    border: solid 1px #ebeef1;
    border-radius: 10px;
    width: 250px;

    input {
      border: none;
    }
  }

  &.details-modal {
    width: 750px;

    .transparent-select {
      width: 230px;
    }

    .number-code {
      .transparent-select {
        width: 100px;
      }
    }

    .scroll-content {
      height: calc(100vh - 35vh);
      padding-top: 20px;
      margin-top: 10px;
      padding-bottom: 100px;
    }
  }

  &.bio-modal {
    width: 750px;

    textarea {
      height: 160px;
      color: #576271;
      resize: none;
      border: 1px solid #ebeef1;
      border-radius: 5px;
      width: 100%;
    }
  }

  &.education-modal {
    width: 600px;

    .scroll-content {
      height: calc(100vh - 37vh);
    }

    .m-section {
      flex: none;
      display: block;
      width: 100%;

      .m-input {
        width: 380px;
        display: block;
        flex: none;
        margin-bottom: 15px;
      }
    }
  }

  &.interest-modal {
    width: 600px;
  }

  &.tools-modal {
    width: 750px;

    .scroll-content {
      height: calc(100vh - 34vh);
      padding-top: 20px;
      margin-top: 10px;
    }
  }

  &.preferences-modal {
    width: 900px;
    padding: 25px;

    &.scroll-content {
      height: auto;
    }
  }

  &.workxp-modal {
    width: 630px;

    .scroll-content {
      height: calc(100vh - 40vh);
      padding-top: 20px;
      margin-top: 10px;
    }

    .remove-item-btn {
      position: absolute;
      right: 10px;
      padding: 4px;
      font-size: 25px;
      width: 25px;
    }
  }
}

.verify-email-popover {
  padding: 12px;

  .header-title {
    color: #1a1e2c;
    font-size: 18px;
    font-weight: 600;
  }
}

.back-button {
  @apply flex relative;
  z-index: 10;

  a {
    @apply flex;
  }

  img {
    width: 10px;
    margin-right: 25px;
  }

  .title {
    font-size: 18px;
    display: none;
  }
}

/***************** UI OVERRIDE ************/
.ant-picker-header {
  button {
    display: inline-block;

    &:hover {
      border-radius: none;
      background-color: transparent;
    }
  }
}

.ant-picker-content {
  tr {
    border: none;
  }

  th,
  td {
    text-align: center;
  }
}

/*************** MOBILE DESIGN *********/

.mobile-menu-toggle {
  display: none;
}

@media (max-width: $mobile) {
  #main {
    &.has-back-link {
      .sidebar-toggle {
        display: none;
      }

      .back-button {
        margin-left: 5px;

        .title {
          font-size: 15px;
          position: relative;
          top: -2px;
          // display: block;
        }

        img {
          width: 10px;
        }
      }
    }
  }

  .no-mobile {
    display: none;
  }

  .verify-email-popover {
    left: 0px !important;
    width: 100%;
    border-radius: 0px;
    padding: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .qrs-datepicker {
    position: relative;
    margin-top: 0px;
  }

  .qrs-modal {
    left: 0px;
    top: 70px;
    transform: none;
    border-radius: 0px;
    z-index: 99999999999999;
    position: fixed;
    width: 100%;

    .mobile-popup-close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      display: block;
      color: #aaa;
      font-size: 20px;
    }

    .profile-edit-modal {
      width: 100% !important;
      // height: calc(100vh - 5vh);
      height: calc(100vh - 10px);

      .section-box {
        .columns {
          width: 100%;
        }

        &.spaced {
          border-bottom: dashed 1px #ccc;

          .form-input {
            margin-bottom: 30px !important;
          }

          .bday-sbox {
            margin-bottom: 65px !important;
          }
        }
      }

      &.details-modal {
        .section-box {
          .form-input {
            width: 100%;
            margin-bottom: 10px;

            input {
              width: calc(100% - 10px);
            }
          }

          .remove-item-btn {
            padding: 3px 8px;
            position: absolute;
            right: 0px;
            top: -10px;
          }
        }

        .scroll-content {
          width: calc(100% + 10px);
          height: calc(100vh - 300px);
        }
      }

      &.education-modal {
        .m-section {
          .m-input {
            width: 100%;
          }
        }

        .scroll-content {
          height: calc(100vh - 150px);
        }
      }

      &.interest-modal {
        overflow: auto;
        height: calc(100vh - 80px);
      }

      &.workxp-modal {
        .scroll-content {
          height: calc(100vh - 230px);
        }
      }
    }
  }

  .qrs-dashboard-cards {
    margin: 12px;
    max-width: none;
    width: calc(100% - 24px);
    height: auto;
  }

  .mobile-menu-toggle {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #aaa;
    display: block;
  }

  .qrs-faq-container {
    margin-top: 25px;
    width: 100%;
    padding: 15px;
  }

  .qrs-pap-box {
    box-shadow: none;

    .left-navig {
      width: 100%;
      height: auto;

      .menu-tab {
        position: static;
        transform: none;
        text-align: center;
        background: #fff;

        .item {
          width: 33%;
          display: inline-block;
          font-size: 12px;
          vertical-align: top;
          height: 50px;
          padding-top: 18px;
        }
      }
    }

    .right-navig {
      width: 100%;
      font-size: 15px;
    }
  }
}

/* Lanscape Controls */

@media (min-height: $landscape-min) and (max-height: $landscape-max) {
  .qrs-modal {
    top: 58px;
    overflow: auto;
    height: calc(100% - 60px);

    .scroll-content {
      overflow: visible !important;
    }
  }
}

// RED DOT FADE
.red-dot {
  @apply rounded-full;
  width: 15px;
  height: 15px;
  background: #fb6767;
}

.fade-animation {
  opacity: 1;
  animation: fade 2s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

// SHARE COMPONENT
.share-container {
  @apply flex flex-col items-center;

  .social-links {
    @apply flex cursor-pointer w-full;
    padding: 16px 0px 0px 0px;
    margin-bottom: 30px;

    @media (max-width: $small-mobile) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .social-item {
      @apply flex flex-col flex-none;
      margin-left: 30px;

      @media (max-width: $small-mobile) {
        margin-bottom: 20px;
      }

      &:first-child {
        margin-left: 0px;
      }

      img {
        margin-bottom: 15px;
        width: 65px;
      }

      .label {
        font-size: 14px;
        color: #6a7a8b;
        text-transform: none;
        margin: 0px;
      }
    }
  }

  .spacer {
    @apply flex flex-col items-center;
    width: 215px;
    margin-bottom: 27px;

    &:before {
      content: '';
      width: 100%;
      border-bottom: 0.5px solid #8898aa;
    }

    .text {
      @apply text-qureosBlack uppercase;
      margin-top: -15px;
      height: 24px;
      font-size: 18px;
      background: white;
      padding: 0px 15px;
    }
  }

  .footer-link {
    @apply w-full flex;
    max-width: 480px;
    height: 50px;
    background: #f1f9ff;
    border: 1px solid #c5e4fd;
    border-radius: 10px;

    .link-item {
      @apply text-qureosBlack;
      padding: 15px 20px;
      font-size: 14px;
      background: transparent;
    }

    .copy-btn {
      @apply flex flex-none items-center justify-center ml-auto text-qureosBlue font-semibold uppercase cursor-pointer;
      font-size: 14px;
      width: 76px;
      border-left: 1px solid #c5e4fd;
    }
  }
}

// CERTIFICATE
// flex affects the behavior of canvas
.certificate-container {
  @apply w-full h-full bg-white justify-center relative;
  max-width: 1056px;
  max-height: 882px;
  min-height: 200px;
  padding: 10px 50px 20px 30px;
  margin: 0 auto;

  .tools {
    @apply flex flex-col absolute;
    width: 50px;
    top: 55px;
    right: 20px;
    z-index: 2;

    .tool-btn {
      @apply cursor-pointer;
      margin-bottom: 30px;
    }
  }

  .pg-viewer-wrapper {
    overflow-y: hidden;
  }

  canvas {
    box-shadow: #00000026 0px 3px 20px;
    margin: 40px;
  }
}

// SHARE WIDGET
.share-widget {
  @apply flex flex-col;

  textarea {
    resize: none;
  }

  .subtitle {
    font-size: 15px;
    color: #6a7a8b;
  }

  .extra-details {
    @apply flex flex-col;
    margin-bottom: 20px;

    & > .title {
      @apply font-semibold text-qureosBlack;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .sub-details {
    @apply flex flex-col;
    margin-bottom: 16px;

    & > .title {
      @apply font-semibold text-qureosBlue;
      font-size: 15px;
      margin-bottom: 5px;
    }
  }

  .social-media-custom-content {
    width: 100%;

    .f-btn {
      &.back-btn {
        margin-left: 0;
      }

      @apply flex items-center justify-center bg-qureosBlue ml-auto;
      width: 100px;
      height: 45px;
      color: white;
      font-size: 20px;
      cursor: pointer;
      border-radius: 5px;
    }

    .header {
      margin-bottom: 15px;

      .back-btn {
        @apply flex text-qureosBlack cursor-pointer;

        img {
          width: 10px;
          height: 10px;
          align-self: center;
          margin-right: 5px;
        }
      }
    }
  }
}

// MODALS

.share-modal {
  width: 579px !important;

  .ant-modal-body {
    padding: 20px 0px 0px 0px !important;
  }

  &.auto-height {
    .ant-modal-content {
      min-height: initial;
    }
  }

  .ant-modal-content {
    @apply flex flex-col;
    min-height: 300px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #6a708026;
    border-radius: 10px;
    padding: 0px 30px 35px 30px;
  }
}

.apprentice-share-modal {
  .ant-modal-close-x {
    position: absolute;
    right: 50%;
    height: 28px;
    width: 28px;
    top: -34px;
  }

  .ant-modal-body {
    .mentor-card {
      box-shadow:
        0px 6.50909px 9.76364px -1.95273px rgba(0, 0, 0, 0.1),
        0px 2.60364px 3.90545px -1.30182px rgba(0, 0, 0, 0.05);
    }
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  @apply text-qureosPrimary bg-transparent;
}

.join-community-modal {
  width: 500px !important;

  .ant-modal-body {
    padding: 20px 0px 0px 0px !important;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    @apply flex flex-col justify-center;
    min-height: 100px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #6a708026;
    border-radius: 10px;
    padding: 0px 30px 35px 30px;

    .cont {
      @apply flex flex-col mt-2 w-full justify-center items-center;

      .content-text {
        @apply flex font-semibold text-qureosBlack mb-5;
        font-size: 18px;
      }

      .modal-btn {
        @apply flex items-center justify-center cursor-pointer;
        border-radius: 23px;
        padding: 9px;
        color: white;
        font-size: 15px;
        height: 35px;
        min-width: 175px;
        width: fit-content;

        @media (max-width: $small-mobile) {
          line-height: 0px;
        }

        &.gray {
          background: #b2b2b2;
        }
      }
    }
  }
}

.generic-title {
  @apply flex w-full;
  font-size: 18px;
  font-weight: 600;
  color: #1a1e2c;
  padding-left: 20px;
  margin-bottom: 20px;
  max-width: 300px;

  .text {
    min-width: 100px;
  }
}

.reference-copied {
  @apply absolute text-white rounded-full;
  background: rgba(0, 0, 0, 0.46);
  top: -32px;
  right: 0;
  left: auto;
  width: 90px;
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
}

// GLOBAL MODAL
.ant-modal-content {
  @media (max-width: $small-mobile) {
    padding-bottom: 50px;
  }

  @media (max-width: $mobile) {
    height: min-content !important;
    top: auto !important;
  }
}

.confirm-page {
  .phone-prefix {
    flex: none;
    width: 100px;
  }

  .phone-input {
    background: white;
    height: 38px;
    border: solid 1px hsl(0, 0%, 80%);
    margin-left: 10px;
  }
}

.skill-verified-pill {
  @apply w-auto bg-gray-400 rounded-full px-5 py-1 text-white text-sm mr-5 mb-5 flex relative;
  padding-right: 35px;

  img {
    cursor: pointer;
    background: white;
    padding: 5px;
    width: 17px;
    border-radius: 999px;
    height: 17px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.popconfirm-skill-remove {
  max-width: 500px;

  .button {
    @apply px-5 py-1 text-white bg-qureosBlue rounded-full shadow-lg ml-3;

    &.cancel {
      @apply bg-red-400;
    }
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.top-75-percent {
  top: 75%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-primary) !important;
}

.ant-tabs-ink-bar {
  background: var(--theme-primary) !important;
}

.ant-ribbon-text {
  color: black;
  font-weight: 600;
}

.ant-ribbon.ant-ribbon-placement-end {
  border-radius: 6px 6px 0px 6px;
}

.apprentice-tabs .ant-tabs-ink-bar {
  background: #f5ae06 !important;
}

.apprentice-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f5ae06 !important;
}

.corporate-tabs {
  .ant-tabs-tab,
  .ant-tabs-tab:hover,
  .ant-tabs-tab:focus {
    color: #6b7280 !important;
  }

  .ant-tabs-tab:active {
    color: var(--theme-primary) !important;
  }
}

.dob-picker {
  .ant-picker {
    @apply w-full;

    .ant-picker-input {
      input {
        @apply text-base;
      }
    }

    .ant-picker-input {
      ::placeholder {
        @apply text-gray-500;
      }

      .ant-picker-suffix {
        .anticon {
          color: black;
        }
      }
    }
  }

  .ant-picker-focused {
    border-color: rgb(37 99 235) !important;
  }
}

.opacity-black-50 {
  background: rgba(0, 0, 0, 0.5);
}

.apprentice-side-menu-bg {
  background-color: #ffc60012;
}

.ant-table-filter-dropdown-tree {
  label span {
    span {
      margin: 0;
    }
  }
}

.ant-checkbox-group-item {
  @apply mb-3 text-gray-700 font-medium;

  span {
    margin: 0;
  }

  label span {
    span {
      margin: 0;
    }
  }
}

@layer utilities {
  .borderless-shadow {
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(0, 0, 0, 0.06) !important;
    @apply border-0;
  }

  .topless-border {
    box-shadow:
      0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .focus-border {
    @apply relative after:content-[''] after:opacity-100 after:scale-100 after:pointer-events-none after:transition-all after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[calc(100%+7px)] after:h-[calc(100%+7px)] after:rounded-lg after:shadow-[0px_0px_0px_3px_#1dbe65];
    // &::after {
    //   content: '';
    // }
  }

  .bigBox {
    box-shadow: -3px 6px 30px 3px rgba(52, 51, 51, 0.1);
  }

  .smallBox {
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
  }

  .flex-center {
    @apply flex justify-center items-center;
  }
}

.apt-card-shadow {
  box-shadow: -3px 6px 30px 3px rgba(52, 51, 51, 0.1);
}

.dashboard-lp-card {
  min-width: 310px;
}

.w-lp-standard {
  width: 310px;
}

.darkTabs {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8b31ff !important;
  }

  .ant-tabs-ink-bar {
    background: #8b31ff !important;
  }
}

.collapse-content-box {
  .ant-collapse-content-box {
    border: none !important;
  }

  & .ant-collapse-item {
    border: none !important;
  }

  & .ant-collapse-header {
    border: none !important;
  }
}

.select .ant-select-selector {
  height: 37px !important;
  display: flex;
  align-items: center;
}

.projectCollapse {
  .ant-collapse-header {
    transition: all 0.2s ease-in-out;
    padding-inline: 0 !important;
  }

  .ant-collapse-content {
    border-top: 0 !important;
  }

  .ant-collapse-content-box {
    padding-inline: 0 !important;
    padding-top: 0 !important;
  }
}

.projectDrawerHeader {
  .ant-drawer-header {
    border-bottom: 0 !important;
    padding: 16px 24px 0 24px !important;
  }
}

.stripe-payment-element-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

.auth-style {
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)::before {
    border: none;
    background-color: unset !important;
  }
}

.create-job-modal {
  @media (max-width: $mobile) {
    margin: 0;

    .ant-modal-content {
      @apply bg-qureosBgGray;
      margin: 3.5rem auto;
      height: min-content !important;
      max-height: 85vh !important;
      background-clip: padding-box;
      padding-bottom: 0px;
      top: auto !important;
      bottom: 0;
      border-radius: 0px !important;
    }
  }
}

.sourcing-layout {
  @media (min-width: '640px') {
    .ant-layout-sider {
      min-width: 344px !important;
    }
  }

  @media (max-width: '640px') {
    .ant-layout-sider {
      min-width: 85vw !important;
    }
  }
}

.ai-interview-tooltip {
  z-index: 1000;

  .ant-tooltip-inner {
    @apply bg-gray-900 rounded-md text-white w-64 text-xs;
  }
}

.ai-interview-autocomplete {
  @apply border border-gray-300 rounded-md overflow-hidden w-full;

  .ant-input {
    @apply border-none text-sm;
    padding: 'revert-layer';
  }

  .ant-input-group-addon {
    background: none;

    button {
      @apply border-none text-gray-900 p-1;
      background: none;
      box-shadow: none;

      span {
        @apply text-lg text-gray-400;
      }
    }
  }
}

.ai-interview-question {
  scrollbar-width: thin;
  scrollbar-color: gray;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: black;
  }
}

.linkedIn-login-modal {
  .ant-modal-header {
    border: none;
  }

  @media (max-width: $mobile) {
    margin: 0;

    .ant-modal-content {
      margin: 3.5rem 10vw;
      height: min-content !important;
      max-width: 550px !important;
      max-height: 85vh !important;
      background-clip: padding-box;
      padding-bottom: 0px;
      border-radius: 0px !important;
    }
  }

  @media (max-width: $small-mobile) {
    margin: 0;

    .ant-modal-content {
      margin: 3.5rem auto;
    }
  }
}

.iris-filter-model:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;
}

.iris-filter-model:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.iris-filter-model {
  .ant-checkbox-wrapper {
    display: -webkit-inline-box !important;
    margin: 0;

    .ant-checkbox {
      height: 16px;
    }
  }
}

.table-wrapper {
  .ant-table {
    .ant-table-selection-column {
      padding-top: 10px;

      .ant-checkbox-wrapper {
        height: 16px;

        .ant-checkbox {
          height: 16px;

          &:hover {
            .ant-checkbox-inner {
              border-color: #898787;
            }
          }
        }
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: #f3f1f1;
    }
  }
}

.apply-job-modal {
  .ant-modal-body {
    padding: 10px 25px;
    max-height: 80vh;
    overflow-y: auto;
  }

  @media (max-width: $small-mobile) {
    .ant-modal-body {
      max-height: 55vh;
    }
  }

  .ant-modal-content {
    padding-bottom: 0;
  }
}

.ant-select-selection-overflow {
  .ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.iris-modal-select {
  .ant-select-selector {
    padding: 8px;
    min-height: 20px !important;

    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
          background: transparent;
          border: none;
          padding: 0;
        }
      }
    }
  }
}

.job-index-department-filter {
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
  }
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: gray;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: black;
  }
}

.candidate-upload-modal {
  .ant-upload-list-item-card-actions {
    .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }
  }

  .ant-progress-line {
    display: none;
  }
}

.campaign-popover {
  .ant-popover-arrow {
    display: none !important;
  }

  .ant-popover-inner {
    @apply rounded-md;
  }

  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-placement-bottom {
    padding: 0 !important;
  }
}

.campaign-dropdown {
  .ant-select-selector {
    padding-left: 0 !important;
    padding-right: 5px !important;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }
}

.ai-screening-table {
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    @apply bg-qureosPrimary rounded-sm border-0;
  }
  .ant-table-row-selected,
  .ant-checkbox-wrapper-checked {
    td {
      background-color: white !important;
    }

    .ant-checkbox-inner {
      @apply bg-qureosPrimary rounded-sm border-0;
    }
  }

  tr {
    @apply border-2 border-b-qureosBluishGray;
    td {
      border-bottom: none;
    }
  }
  label {
    margin-top: -4px !important;
    span {
      margin-bottom: 0 !important;
    }
  }

  .ant-table-selection-column {
    display: flex;
    justify-content: center;

    .ant-checkbox-inner::after {
      position: relative !important;
    }
  }

  .ai-screening-table-expandable {
    td {
      padding: 0 !important;
    }
  }
}

.v2-antd-carousel {
  .slick-dots {
    width: auto;

    li {
      @apply border-2 border-black;

      &.slick-active {
        width: auto;
        @apply border-qureosPrimary;
      }

      border-radius: 0;
      height: 5px;

      button {
        height: 5px;
      }
    }
  }
}

.ck-editor__corporate {
  .ck-editor {
    @apply rounded-md;
  }
}

.custom-checkbox-group {
  @apply flex flex-col gap-2;

  .ant-checkbox-wrapper {
    @apply flex items-center;
    margin-left: 0;

    span {
      margin-bottom: 0 !important;
    }
  }

  .ant-checkbox-checked::after {
    height: 16px !important;
  }
}

.custom-collapse {
  .ant-collapse-header {
    @apply bg-gray-100 rounded-tl-lg rounded-tr-lg w-full justify-between;

    > :first-child {
      @apply order-last;
    }
  }

  &.ant-collapse-ghost {
    & > .ant-collapse-item {
      border-bottom: 1px solid theme('colors.gray.300') !important;
    }
  }

  .ant-collapse-item {
    @apply shadow-lg rounded-lg border border-gray-300 border-b;
    border-bottom-width: 1px !important;

    &:not(:first-child) {
      @apply mt-5;
    }

    &:last-child {
      @apply rounded-lg;
    }
  }
}
